import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Constant";

const Otpverify = () => {
  const [otp, setOtp] = useState("");
  const [resendMessage,setresendMessage] = useState();
  const navigate = useNavigate();

  //   const submitHandler = async (e) => {
  //     e.preventDefault();
  //     if (!name || !number || !password) {
  //       console.log("data is not filled");
  //       return;
  //     }
  //     try {
  //       const config = {
  //         headers: {
  //           "Content-type": "application/json",
  //         },
  //       };
  //       const response = await axios.post(
  //         "http://localhost:5000/api/userprofile/signup",
  //         {
  //           name,
  //           number,
  //           password,
  //         },
  //         config
  //       );
  //       // console.log('Server response:', response);
  //       // localStorage.setItem("userInfo", response);
  //       console.log("done");
  //       navigate("/Otpverify")

  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const Usernumber = localStorage.getItem("Usernumber");
  const UsernumberInString = Usernumber?.toString();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/api/userprofile/verify`, {
        number: UsernumberInString,
        otp,
      });
      // console.log("It's is verified");
      // await navigate("/login");

      if (
        response.status === 200 &&
        response.data.message === "OTP verified successfully"
      ) {
        console.log("OTP verified successfully");
         navigate("/login");
      } else {
        console.log("Error verifying OTP");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ResendOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/api/userprofile/resendotp`,
        {
          number: UsernumberInString,
          otp,
        }
      );
      if (
        response.status === 200 &&
        response.data.message === "Otp is resended"
      ) {
        console.log("Otp is resended");
        setresendMessage("Otp is resended 😊")
       // navigate("/login")
        window.location.reload("/Otpverify")
      }else{
        console.log("Otp not sended")
        setresendMessage("Otp not sended 😥");
      }

      console.log("It's is verified");
      //  navigate("/Home")
      //   window.location.href = "/Home";
    } catch (error) {
      console.log(error);
    }
  };


  console.log("This is the Usernumber", localStorage.getItem("Usernumber"));

  return (
    <div style={{backgroundColor:'black',height:'1000px'}}>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          position: "relative",
          top: "180px",
          marginRight: "20%",
          marginLeft: "20%",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            position: "fixed",
            borderRadius: "10px",
            height: "350px",
            width: "300px",
            boxShadow:
              "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
          }}
        >
          {/* <h1 style={{ color: "white", textAlign: "center" }}>OTP</h1> */}
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            <CountdownCircleTimer
              // style={{ color: "purple" }}
              isPlaying
              duration={120}
              // colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colors="lightblue"
              // colorsTime={[7, 5, 2, 0]}
              size={80}
              strokeWidth={10}
              trailColor="black" // Blue gradient
            >
              {({ remainingTime }) => (
                <h3 style={{ color: "white", textAlign: "center" }}>
                  OTP
                  <br></br>
                  {remainingTime}
                </h3>
              )}
            </CountdownCircleTimer>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              style={{
                marginTop: "25px",
                borderWidth: 4,
                borderRadius: 50,
                borderColor: "white",
                alignSelf: "center",
                width: 250,
                height: 25,
                shadowColor: "white",
                elevation: 1,
                backgroundColor: "#323232",
                color: "white",
                alignContent: "center",
              }}
              placeholder="OTP"
              placeholderTextColor="white"
              type="number"
              onChange={(e) => setOtp(e.target.value)}
              value={otp}
            />

            <button
              onClick={submitHandler}
              style={{
                marginTop: "25px",
                backgroundColor: "black",
                border: "2px solid lightblue",
                borderRadius: "100px",
                width: 250,
                height: "50px",
                shadowColor: "white",
                boxShadow:
                  "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
              }}
            >
              {/* <Link to="/Login"> */}
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 25,
                  letterSpacing: 1,
                  color: "white",
                  textAlign: "center",
                  marginTop: "10px",
                  textDecoration: "none",
                }}
              >
                Verify
              </p>
              {/* </Link> */}
            </button>
            <button
              onClick={ResendOtp}
              style={{
                color: "grey",
                backgroundColor: "black",
                marginTop: "50px",
              }}
            >
              Resend
            </button>
            <p style={{color:'lightgreen'}}>{resendMessage}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Otpverify;
