import React, { useEffect, useState } from "react";
import Products from "./Items/Products";

export default function NotFound() {
  const [userData, setUserData] = useState();

  useEffect(() => {
    const savedData = localStorage.getItem("userInfo");
    try {
      const parsedData = savedData ? JSON.parse(savedData) : null;
      console.log("Parsed Data..................:", parsedData.number);
      setUserData(parsedData);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setUserData(null); // Set userData to null in case of parsing error
    }
  }, []);
  return (
    <div
      style={{
        color: "white",
        marginTop: "100px",
        alignContent: "center",
        // display: "block",
        // marginLeft: "auto",
        // marginRight: "auto",
      }}
    >
      {/* <h1 style={{ textAlign: "center" }}>Not Found 🙄</h1> */}
      {userData ? (
        <>
          <h2
            style={{
              color: "white",
              marginTop: "90px",
              marginLeft: "25px",
              fontFamily: "monospace",
            }}
          >
            Hey {userData.name} 🙋‍♂️
          </h2>
        </>
      ) : (
        <>
        <h2
            style={{
              color: "white",
              marginTop: "90px",
              marginLeft: "25px",
              fontFamily: "monospace",
            }}
          >
            Hey 🙋‍♂️
          </h2>
        </>
      )}
      <Products />
    </div>
  );
}
