import React, { useState, useEffect } from "react";
import Products from "../Items/Products";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Cart from "../Cart/Cart";
import flowe from '../../Assets/Logos/flowe.png'
const Home = () => {
  const [userData, setUserData] = useState();

  useEffect(() => {
    const savedData = localStorage.getItem("userInfo");
    try {
      const parsedData = savedData ? JSON.parse(savedData) : null;
      console.log("Parsed Data..................:", parsedData.number);
      setUserData(parsedData);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setUserData(null); // Set userData to null in case of parsing error
    }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
  };
  // const number = '9044170076' ;
  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div>
        <div style={{ marginTop: "75px" }}>
          {userData ? (
            <>
              {/* <p>Name: {userData.name}</p>
          <p>Name: {userData.name}</p>
          <p>Name: {userData.name}</p> */}
              {/* <p style={{marginTop:'100px'}} >number: {userData.number}</p> */}
              <h2  style={{color:'black',marginTop:'90px',marginLeft:'25px',fontFamily:'monospace'}}>Hey {userData.name} 🙋‍♂️</h2>

              {/* <button onClick={logoutHandler}>Logout</button> */}
            </>
          ) : (
            <>
             <h2
            style={{
              color: "black",
              marginTop: "90px",
              marginLeft: "25px",
              fontFamily: "monospace",
            }}
          >
            Hey 🙋‍♂️
          </h2>
              {/* <p>No user data found in localStorage.</p>
          <p>No user data found in localStorage.</p>
          <p>No user data found in localStorage.</p> */}
            </>
          )}
          {/* <img src={{flowe}} /> */}

          <Products />
          {/* <Cart/> */}
          {/* {userData ? <CartComponent number={userData.number} /> : <p>no number</p>} */}
        </div>
      </div>
    </SkeletonTheme>
  );
};
export default Home;
