import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../Constant";
import { useAge } from "../../Provider/ContextProvider";

const Login = () => {
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [LoginMessage, setLoginMessage] = useState();
  //const [loggedIn, setLoggedIn] = useState(false);
  //  const [ourmessage, Setourmessage] = useState("");
  const navigate = useNavigate();

  const { userData, setUserData } = useAge();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!number || !password) {
      console.log("data is not filled");
      return;
    }
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}/api/userprofile/login`,
        {
          number,
          password,
        },
        config
      );
      localStorage.removeItem("CartBtnClicked");

      //   Setourmessage(response.data.message);

      // console.log("LOGIN.................");
      //  console.log("Server response:", response);
      // const userName = response.data.data.name;
      // localStorage.setItem("userName", userName);

      //localStorage.setItem("userInfo",  response);

      console.log("Server Response:", response.data.message);

      localStorage.setItem("userInfo", JSON.stringify(response.data));
      // setUserData(userinfo);
      navigate("/Home");
      window.location.reload();

      console.log("done");
      // setLoggedIn(true);

    
   
    } catch (error) {
      console.log(error);
     
    }
  };
  // console.log("this is the messsage", ourmessage);
  return (
    <div style={{backgroundColor:'black',height:'1000px'}}>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          position: "relative",
          top: "150px",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            position: "fixed",
            borderRadius: "10px",
            height: "420px",
            width: "300px",
            boxShadow:
              "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>Login</h1>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              style={{
                marginTop: "25px",
                borderWidth: 4,
                borderRadius: 50,
                borderColor: "white",
                width: 250,
                height: 25,
                shadowColor: "white",
                elevation: 1,
                backgroundColor: "#323232",
                color: "white",
                alignContent: "center",
                alignSelf: "center",
              }}
              placeholder="ᴘʜᴏɴᴇ"
              placeholderTextColor="white"
              type="number"
              onChange={(e) => setNumber(e.target.value)}
              value={number}
            />
            <br></br>
            {/* <p style={{ color: "white" }}>This is response{ourmessage}</p> */}

            <input
              style={{
                marginTop: "25px",
                borderWidth: 4,
                borderRadius: 50,
                borderColor: "white",
                width: 250,
                height: 25,
                backgroundColor: "#323232",
                color: "white",
              }}
              placeholder="ᴘᴀꜱꜱᴡᴏʀᴅ"
              placeholderTextColor="white"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <br></br>

            {/* <button
              onClick={submitHandler}
              style={{
                marginTop: "25px",
                backgroundColor: "black",
                borderRadius: "100px",
                width: 250,
                height: "50px",
                color: "white",
                border: "2px solid lightblue",
                WebkitTapHighlightColor: "transparent",
              }}
            >
              <h3
                style={{
                  fontWeight: "bold",
                  fontSize: 25,
                  letterSpacing: 1,
                  textAlign: "center",
                  marginTop: "5px",
                  textDecorationColor: "white",
                }}
              >
                ​🇱​​🇴​​🇬​​🇮​​🇳​
              </h3>
            </button> */}
            {/* <p style={{ textAlign: "center", color: "lightgreen"}}>
              {LoginMessage}
            </p> */}
            <button
              style={{
                backgroundColor: "black",
                border: "2px solid lightblue",
                width: 250,
                height: "50px",
                borderRadius: "100px",
                boxShadow:
                  "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
              }}
              onClick={submitHandler}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 25,
                  letterSpacing: 1,
                  textAlign: "center",
                  color: "white",
                  // display:'block',
                  margin: "auto",
                }}
              >
                ʟᴏɢɪɴ
              </p>
            </button>

            <p style={{ textAlign: "center", color: "grey" }}>
              <Link
                style={{ textDecoration: "none", color: "grey" }}
                to="/Signup"
              >
                ​ᴄʀᴇᴀᴛᴇ ᴀᴄᴄᴏᴜɴᴛ → Signup
              </Link>
            </p>

            <Link style={{ textDecoration: "none" }} to="/ForgotPassword">
              <button
                style={{
                  backgroundColor: "black",
                  color: "lightblue",
                  border: "none",
                }}
              >
                Forgot Password ?
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
