import React, { createContext, useState, useContext } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [addToCart, SetAddToCart] = useState("...Dat...");
  const [showCartPopup, setShowCartPopup] = useState(false); // State to manage popup visibility


  //   const addToCart = (item) => {
  //     setCartItems([...cartItems, item]);
  //   };

  //   const removeFromCart = (itemId) => {
  //     const updatedCart = cartItems.filter((item) => item.id !== itemId);
  //     setCartItems(updatedCart);
  //   };

  return (
    <CartContext.Provider value={{ addToCart, SetAddToCart,showCartPopup,setShowCartPopup }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};

export default CartProvider;
