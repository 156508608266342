// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useAge } from '../../Provider/ContextProvider';
// const Cart = () => {
//   const [cartData, setCartData] = useState([]);
//   const {userData } = useAge();

//   // const [number, setNumber] = useState();
//   // const [userData, setUserData] = useState();

//   // useEffect(() => {
//   //   const savedData = localStorage.getItem("userInfo");
//   //   try {
//   //     const parsedData = savedData ? JSON.parse(savedData) : null;
//   //     console.log("Parsed Data..................:", parsedData.number);
//   //     setNumber(parsedData.number);
//   //     setUserData(parsedData);
//   //   } catch (error) {
//   //     console.error("Error parsing JSON:", error);
//   //     setUserData(null); // Set userData to null in case of parsing error
//   //   }
//   // }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`http://localhost:5000/api/userprofile/${userData.number}`);
//         setCartData(response.data.cart);
//         console.log(response.data.cart)
//       } catch (error) {
//         console.error('Error fetching cart data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div style={{marginTop:'100px'}}>
//       <h2>User's Cart</h2>
//       <ul>
//         {cartData.map((item) => (
//           <li key={item._id}>
//             Product: {item.name}, Price: {item.price}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default Cart;

import React, { useState, useEffect, useCallback } from "react";
import axios, { Axios } from "axios";
import { useAge } from "../../Provider/ContextProvider";
import karmyogiSweatshirt from "../../Assets/Product_images/KarmayogiSweatshirt.png";
import forwardimage from "../../Assets/Logos/forward.png";
import deleteimage from "../../Assets/Logos/delete.png";
import Razorpay from "react-razorpay";
import { useCart } from "../../Provider/CartProvider";
import { BASE_URL } from "../../Constant";
const Order = () => {
  const [cartData, setCartData] = useState([]);
  const [quantity, setQuantity] = useState();
  const [total, setTotal] = useState(0);
  const [productInfo, setProductInfo] = useState({});
  const [overallTotal, setOverallTotal] = useState(0);
  const [size, setSize] = useState({});
  const [pincode, setPincode] = useState();
  const [address, setAddress] = useState();
  const [price, setprice] = useState();

  const { userData } = useAge();
  const {showCartPopup, setShowCartPopup} = useCart();

  const handlePlus = (productId, productPrice) => {
    setProductInfo((prevInfo) => ({
      ...prevInfo,
      [productId]: {
        quantity: (prevInfo[productId]?.quantity || 0) + 1,
        total: (prevInfo[productId]?.total || 0) + parseFloat(productPrice),
      },
    }));
    setOverallTotal((prevTotal) => prevTotal + parseFloat(productPrice));
    // setQuantity(quantity+1);
    // setTotal(total+999);
  };

  const handleMinus = (productId, productPrice) => {
    if (productInfo[productId]?.quantity > 0) {
      setProductInfo((prevInfo) => ({
        ...prevInfo,
        [productId]: {
          quantity: (prevInfo[productId]?.quantity || 0) - 1,
          total: (prevInfo[productId]?.total || 0) - productPrice,
        },
      }));
      setOverallTotal((prevTotal) => prevTotal - productPrice);
      // setQuantity(quantity-1);
      // setTotal(total-999);
    }
  };

  const handleSizeChange = (itemId, selectedSize) => {
    setSize((prevSizes) => ({
      ...prevSizes,
      [itemId]: selectedSize,
    }));
  };

  const quantityinstring = quantity?.toString();
  console.log("quantity", quantityinstring);

  const sizeinString = size?.toString();
  console.log(size);

  console.log("total price", overallTotal);

  const pincodeinString = pincode?.toString();
  console.log("here is the pincode", pincode);

  const addressinString = address?.toString();
  console.log("here is the address", address);
  // const [number, setNumber] = useState();
  // const [userData, setUserData] = useState();

  // useEffect(() => {
  //   const savedData = localStorage.getItem("userInfo");
  //   try {
  //     const parsedData = savedData ? JSON.parse(savedData) : null;
  //     console.log("Parsed Data..................:", parsedData.number);
  //     setNumber(parsedData.number);
  //     setUserData(parsedData);
  //   } catch (error) {
  //     console.error("Error parsing JSON:", error);
  //     setUserData(null); // Set userData to null in case of parsing error
  //   }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/userprofile/${userData.number}`
        );
        setCartData(response.data.cart);
        console.log(response.data.cart);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `http://localhost:5000/api/Dashboard/productupload`
  //       );
  //       setProductData(response.data.ProductData);
  //       console.log("uploaded product response", response.data.ProductData);

  //       const data = response.data.ProductData;
  //       const names = data.map(product => product.productName);
  //       const price = data.map(product => product.productPrice)
  //       const id = data.map(product => product._id)

  //       setProductNames(names);
  //       setProductPrice(price);
  //       setProductId(id);

  //       console.log(names)
  //       console.log(price)
  //       console.log(id)

  //     } catch (error) {
  //       console.error("Error fetching cart data:", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  if (overallTotal > 100) {
    const overallTotalplusDelivery = overallTotal + 100;
    var payingamountinstring = overallTotalplusDelivery.toString();
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const makePayment = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("you are offline");
      return;
    }

    var options = {
      description: "Credits towards consultation",
      // image: 'https://i.imgur.com/3g7nmJC.png',
      currency: "INR",
      key: "rzp_live_FEBzZ0q1oVWWzK", // Your api key
      amount: `1` + "00",
      name: "Cramox",
      prefill: {
        email: "void@razorpay.com",
        contact: "9191919191",
        name: "Razorpay Software",
      },
      theme: { color: "#000000" },
      handler: function (paymentresponse) {
        console.log("Payment successful:", paymentresponse);
        buynow(paymentresponse);
      },
    };
    // useRazorpay.open(options)
    //   .then(data => handlePaymentSuccess(data.razorpay_payment_id))
    //   .catch(error => handlePaymentError(error));
    const paymentobject = window.Razorpay(options);
    paymentobject.open();
  };

  const buynow = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const itemsToBuy = cartData.map((item) => ({
        productId: item._id,
        name: item.name,
        price: productInfo[item._id]?.total || 0,
        quantity: productInfo[item._id]?.quantity || 0,
        size: size[item._id] || "default",
      }));

      const response = await axios.post(
        `${BASE_URL}/api/user/buynow`,
        {
          // productId: "someProductId",
          // name: "Karma yogi Sweathirt",
          //   price: payingamountinstring,
          // number: userData ? userData.number : null,
          // quantity: quantityinstring,
          items: itemsToBuy,
          number: userData ? userData.number : null,
          pincode: pincodeinString,
          address: addressinString,
        },
        config
      );
      console.log("hey here is the cart ", response.data);
    } catch (error) {
      console.error(error);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Server responded with:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from the server");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error.message);
      }
    }
  };
  // const handlePaymentSuccess = async paymentId => {
  //   console.log(`Payment Successful: ${paymentId}`);
  //   // navigation.navigate('Paymentsuccess');
  // };

  // const handlePaymentError = async error => {
  //   console.log(`Error: ${error.code} |  ${error.description}`);
  //   await localStorage.removeItem('itemSecond');
  // };

  const handleRemoveItem = async (_id) => {
    try {
      const updatedCartData = cartData.filter((item) => item._id !== _id);
      setCartData(updatedCartData);
      await axios.delete(
        `${BASE_URL}/api/userprofile/cart/${userData.number}/${_id}`
      );

      const removeItemFromCart = localStorage.removeItem('CartBtnClicked')
      if(removeItemFromCart === "true"){
        setShowCartPopup(true);
      }else{
        setShowCartPopup(false);
      }

      

      //window.location.reload("/Cart")
    } catch (error) {
      alert("Failed to remove item from cart. Please try again.");
    }
  };



  return (
    <div style={{ marginTop: "100px" }}>
      <h2 style={{ marginLeft: "25px", color: "white" }}>Cart Checkout</h2>

      {/* ITems */}

      {cartData.map((item) => (
        <div
          key={item._id}
          style={{
            margin: "25px",
            backgroundColor: "white",
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              borderRadius: "10px",
              borderWidth: "10px",
              borderColor: "red",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <img
              src={`${BASE_URL}/${item.Cartimage}`}
              style={{
                height: "50px",
                width: "50px",
                marginTop: "10px",
                marginLeft: "10px",
                borderColor: "red",
                borderWidth: "20px",
                borderRadius: "10px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flex: "1",
                margin: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {item.name}
              </p>
              <img
                onClick={() => handleRemoveItem(item._id)}
                src={deleteimage}
                style={{ height: "20px", marginTop: "15px" }}
              />

              <p style={{ color: "blue" }}>
              ₹    {productInfo[item._id]?.total || 0}
              </p>
            </div>
          </div>

          {/* size & Increase Decrease button */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              margin: "5px",
            }}
          >
            <div style={{ marginTop: "15px" }}>
              <input
                type="radio"
                id="S"
                name={`size-${item._id}`}
                value="S"
                // onChange={() =>
                //   setProductInfo((prevInfo) => ({
                //     ...prevInfo,
                //     [item._id]: { ...prevInfo[item._id], size: "S" },
                //   }))
                // }
                // onChange={() => {
                //   setProductInfo((prevInfo) => ({
                //     ...prevInfo,
                //     [item._id]: { ...prevInfo[item._id], size: "S" },
                //   }));
                //   setSize("S"); // Assuming setSize is a state update function for size
                // }}
                checked={size[item._id] === "S"}
                onChange={() => handleSizeChange(item._id, "S")}
              />
              <label htmlFor="S">S</label>

              <input
                type="radio"
                id="M"
                name={`size-${item._id}`}
                value="M"
                // onChange={() => {
                //   setProductInfo((prevInfo) => ({
                //     ...prevInfo,
                //     [item._id]: { ...prevInfo[item._id], size: "M" },
                //   }));
                //   setSize("M"); // Assuming setSize is a state update function for size
                // }}
                checked={size[item._id] === "M"}
                onChange={() => handleSizeChange(item._id, "M")}
              />
              <label htmlFor="M">M</label>

              <input
                type="radio"
                id="L"
                name={`size-${item._id}`}
                value="L"
                // onChange={() => {
                //   setProductInfo((prevInfo) => ({
                //     ...prevInfo,
                //     [item._id]: { ...prevInfo[item._id], size: "L" },
                //   }));
                //   setSize("L"); // Assuming setSize is a state update function for size
                // }}
                checked={size[item._id] === "L"}
                onChange={() => handleSizeChange(item._id, "L")}
              />
              <label htmlFor="L">L</label>

              <input
                type="radio"
                id="XL"
                name={`size-${item._id}`}
                value="XL"
                // onChange={() => {
                //   setProductInfo((prevInfo) => ({
                //     ...prevInfo,
                //     [item._id]: { ...prevInfo[item._id], size: "XL" },
                //   }));
                //   setSize("XL"); // Assuming setSize is a state update function for size
                // }}
                checked={size[item._id] === "XL"}
                onChange={() => handleSizeChange(item._id, "XL")}
              />
              <label htmlFor="Xl">XL</label>
              {/* <button onClick={() => handleRemoveItem(item._id)}> */}
              {/* </button> */}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                margin: "10px",
              }}
            >
              <div
                style={{
                  border: "2px solid #D3D3D3",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "2px",
                  alignContent: "center",
                  height: "40%",
                }}
              >
                <button
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                  onClick={() => handleMinus(item._id, item.price)}
                >
                  -
                </button>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    margin: "-10px 20px 30px 20px",
                  }}
                >
                  <p>{productInfo[item._id]?.quantity || 0}</p>
                </p>
                <button
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                  onClick={() => handlePlus(item._id, item.price)}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Bill details */}
      <div
        style={{
          backgroundColor: "white",
          boxShadow:
            " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          margin: "25px",
          borderRadius: "10px",
        }}
      >
        <p
          style={{
            color: "balck",
            fontWeight: "bold",
            marginLeft: "10px",
          }}
        >
          Bill Details
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        >
          <p style={{ color: "black", fontWeight: "500" }}>Items Total</p>
          <p style={{ color: "black", fontWeight: "500" }}>
            ₹ {overallTotal}
          </p>{" "}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        >
          <p style={{ color: "black", fontWeight: "500" }}>Delivery charges</p>
          <p style={{ color: "black", fontWeight: "500" }}>₹ 100</p>{" "}
        </div>
        <hr></hr>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        >
          <p style={{ color: "black", fontWeight: "500" }}>Total</p>
          {overallTotal > 100 ? (
            <p style={{ color: "blue", fontWeight: "500" }}>
              ₹ {overallTotal + 100}
            </p>
          ) : (
            <p style={{ color: "blue", fontWeight: "500" }}>₹ 0</p>
          )}
        </div>
      </div>

      {/* Address */}
      {/* <div style={{ display: "flex", flexDirection: "column", margin: "25px" }}>
        <p style={{ fontWeight: "bold" }}> Delivery Address </p>
        <input
          type="number"
          placeholder="Area Pincode"
          required={true}
          onChange={(e) => setPincode(e.target.value)}
        />

        <input
          type="text"
          placeholder="Detailed Delivery Address"
          onChange={(e) => setAddress(e.target.value)}
          required="true"
        />
      </div> */}


      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "25px",
          //justifyContent: "space-between",
          backgroundColor:'black',
          boxShadow:
          "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
          height:'180px',
          padding:'10px'
        }}
      >
        <p style={{ fontWeight: "bold",color:'white' }}> Delivery Address </p>
          <input
            style={{ height: "25px" }}
            type="number"
            placeholder="Area Pincode"
            required={true}
            onChange={(e) => setPincode(e.target.value)}
          />

          <input style={{marginTop:'26px',height:'25px',}}
            type="text"
            placeholder="Detailed Delivery Address"
            onChange={(e) => setAddress(e.target.value)}
            required="true"
          />

      </div>
      <hr></hr>

      {/* Place order */}
      <div
        style={{
          backgroundColor: "black",
          color: "white",
          fontWeight: "bold",
          height: "50px",
          textAlign: "right",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          margin: "10px",
          borderRadius: "10px",
          // boxShadow:
          //   " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",

          boxShadow:
            "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
        }}
      >
        <div>
          {overallTotal > 100 ? <p> {overallTotal + 100}</p> : <p>₹ 0</p>}
        </div>

        {!productInfo || !pincode || !address ? ( //productinfo is size here i ahev decided size this
          <p style={{ color: "red" }}>choose size & fill address details</p>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
            onClick={buynow}
          >
            <p>Place order </p>
            <img
              //onClick={makePayment}
              style={{ marginTop: "15px", height: "25px", width: "25px" }}
              src={forwardimage}
            />
          </div>
        )}
      </div>

      {/* <ul>
        {cartData.map((item) => (
          <div key={item._id}>
            Product: {item.name}, Price: {item.price}
          </div>
        ))}
      </ul> */}
    </div>
  );
};

export default Order;
