// import React, { useEffect, useState } from "react";
// import axios from "axios";
// const Dashboard = () => {
//   const [newresponseName, setResponseName] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           // "http://localhost:5000/api/printUserCartData" &&
//           "http://localhost:5000/api/printUserProfiles"

//         );
//         console.log("Server Response:..............", response.data);

//         // console.log(
//         //   "cart Response:..............",
//         //   response.data.forEach((userProfile) => {
//         //     console.log("Cart:", userProfile.cart);
//         //   })
//         // );

//         setResponseName(response.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchData();
//   }, []);

//   return (
//     <div
//       style={{ marginTop: "75px", backgroundColor: "white", height: "2000px" }}
//     >
//       <h1
//         style={{
//           textAlign: "center",
//           fontWeight: "bold",
//           fontFamily: "monospace",
//           fontSize: "50px",
//         }}
//       >
//         Dashboard
//       </h1>
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <table
//           style={{
//             border: "10px solid whitesmoke",
//             height: "350px",
//             padding: "10px",
//             fontSize:'15px'
//           }}
//         >
//           <tr style={{ padding: "10px", backgroundColor: "whitesmoke" }}>
//            <th>SrNo</th>
//             <th>Name</th>
//             <th>Number</th>
//             <th>Image</th>
//             <th>DirectOrders</th>
//             <th>Size</th>
//             <th>Quantity</th>
//             <th>Price</th>
//             <th>Product Id</th>
//             <th>Pincode</th>
//             <th>Address</th>
//             <th>Status</th>
//             <th>Shipping Status</th>
//             <th>Submit</th>
//             <th>Generate label</th>

//           </tr>
//           {newresponseName.toReversed().map((item, firstrow) => (
//             <tr
//               style={{
//                 backgroundColor: firstrow === 0 ? "lightgreen" : "whitesmoke",
//                 color: "black",
//                 fontFamily: "monospace",
//               }}
//               key={item._id}
//             >

//                 <td>{firstrow}</td>
//               <td>{item.name}</td>
//               <td>{item.number}</td>


//               <td>
//                image
//               </td>


//               <td>
//                 {item.items.map((cartItem, index) => (
//                   <td key={index}>
//                     <td> {cartItem.name}</td>
//                   </td>
//                 ))}
//               </td>

              
//               <td>
//                 {item.items.map((cartItem, index) => (
//                   <td key={index}>
//                     <td> {cartItem.size}</td>
//                   </td>
//                 ))}
//               </td>

//               <td>
//                 {item.items.map((cartItem, index) => (
//                   <td key={index}>
//                     <td> {cartItem.quantity}</td>
//                   </td>
//                 ))}
//               </td>

//               <td>
//                 {item.items.map((cartItem, index) => (
//                   <td key={index}>
//                     <td> {cartItem.price}</td>
//                   </td>
//                 ))}
//               </td>

//               <td>
//                 {item.items.map((cartItem, index) => (
//                   <td key={index}>
//                     <td> {cartItem._id}</td>
//                   </td>
//                 ))}
//               </td>

//               <td>
//                 {item.items.map((cartItem, index) => (
//                   <td key={index}>
//                     <td> {cartItem.pincode}</td>
//                   </td>
//                 ))}
//               </td>

//               <td>
//                 {item.items.map((cartItem, index) => (
//                   <td key={index}>
//                     <td> {cartItem.address}</td>
//                   </td>
//                 ))}
//               </td>


//               <td>
//                 {item.items.map((cartItem, index) => (
//                   <td key={index}>
//                     <td> {cartItem.address}</td>
//                   </td>
//                 ))}
//               </td>

//               <td>
//                 <select id="ShippingStatus" name="ShippingStatus">
//                   <option value="None">None</option>
//                   <option value="ShippingStarted">Shipping Started</option>
//                 </select>
//               </td>

//               <td
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   marginTop: "15px",
//                 }}
//               >
//                 <button
//                   style={{
//                     color: "White",
//                     backgroundColor: "black",
//                     border: "1px solid black",
//                     borderRadius: "5px",
//                   }}
//                 >
//                   Submit
//                 </button>
//               </td>


//               <td >
//                 <button
//                   style={{
//                     color: "blue",
//                     backgroundColor: "white",
//                     border: "1px solid white",
//                     borderRadius: "5px",
//                     display:'flex',
//                     justifyContent:'center',
//                     marginLeft:'15px',
//                     marginTop:'-12px'
//                   }}
//                 >
//                   Print
//                 </button>
//               </td>

//             </tr>
//           ))}
//         </table>
//       </div>
//     </div>
//   );
// };
// export default Dashboard;
