import React from "react";

export default function () {
  return (
    <p style={{ marginTop: "100px" }}>
      Return & Refund Policy Updated at 2024-01-31 Definitions and key terms To
      help explain things as clearly as possible in this Return & Refund Policy,
      every time any of these terms are referenced, are strictly defined as:
      -Company: when this policy mentions ,Company ,we, us, or our, it refers to
      (Being Sanatan), that is responsible for your information under this
      Return & Refund Policy. -Customer:¬†refers to the company, organization or
      person that signs up to use the (BeingSanatan) Service to manage the
      relationships with your consumers or service users. -Device:¬†any internet
      connected device such as a phone, tablet, computer or any other device
      that can be used to visit (BeingSanatan) and use the services. -Service:
      refers to the service provided by (Being Sanatan) as described in the
      relative terms (if available) and on this platform. -Website: (Being
      Sanatan)."‚ site, which can be accessed via this URL: (BeingSanatan.in)
      -You: a person or entity that is registered with (BeingSanatan) to use the
      Services. Return & Refund Policy Thanks for shopping at (Being Sanatan).
      We appreciate the fact that you like to buy the stuff we build. We also
      want to make sure you have a rewarding experience while you‚are exploring,
      evaluating, and purchasing our products. As with any shopping experience,
      there are terms and conditions that apply to transactions at (Being
      Sanatan). We‚will be as brief as our attorneys will allow. The main thing
      to remember is that by placing an order or making a purchase at (Being
      Sanatan), you agree to the terms set forth below along with Policy. If
      there‚is something wrong with the product/service you bought, or if you
      are not happy with it, you will not be able to issue a refund for your
      item. Refunds We at¬† ourselves to serving our customers with the best
      products. Every single product that you choose is thoroughly inspected,
      checked for defects and packaged with utmost care. We do this to ensure
      that you fall in love with our products. Sadly, there are times when we
      may not have the product(s) that you choose in stock, or may face some
      issues with our inventory and quality check. In such cases, we may have to
      cancel your order. You will be intimated about it in advance so that you
      don't have to worry unnecessarily about your order. If you have purchased
      via Online payment (not Cash on Delivery), then you will be refunded once
      our team confirms your request. We carry out thorough quality check before
      processing the ordered item. We take utmost care while packing the
      product. At the same time we ensure that the packing is good such that the
      items won ‚ get damaged during transit. Please note that (Being Sanatan) is
      not liable for damages that are caused to the items during transit or
      transportation. We follow certain policies to ensure transparency,
      efficiency and quality customer care: -We DO NOT allow returns on sold
      products - online or in retail outlets. -We DO NOT accept returned goods,
      as we believe that customers should get the best quality products.
      -Refunds are NOT given for any purchases made - be they online or in
      retail store. -We DO NOT encourage exchanges of our products. -We DO NOT
      engage in reselling used products and discourage the same, because we
      cannot ensure the best quality products for our customers. For
      International Orders: -We DO NOT support Exchanges or Returns. -If you
      cancel the order before we process it and dispatch for shipping, a refund
      can be processed. Orders generally take 1-2 days to process before
      dispatch. -Orders already in shipping cannot be returned, cancelled or
      refunded. -If you face any issues, please contact our Support Team
      immediately. Your Consent By using our website, registering an account, or
      making a purchase, you hereby consent to our Return & Refund Policy and
      agree to its terms. Changes To Our Return & Refund Policy Should we
      update, amend or make any changes to this document so that they accurately
      reflect our Service and policies. Unless otherwise required by law, those
      changes will be prominently posted here. Then, if you continue to use the
      Service, you will be bound by the updated Return & Refund Policy. If you
      do not want to agree to this or any updated Return & Refund Policy, you
      can delete your account. Contact Us If, for any reason, You are not
      completely satisfied with any good or service that we provide, don't
      hesitate to contact us and we will discuss any of the issues you are going
      through with our product. -Via Email: (beingsatyasanatani@gmail.com)
    </p>
  );
}
