import React, { useState, useEffect } from "react";
import Header from "../../Components/NavigationBar/Header";
import SignoutDark from "../../Assets/Logos/Auth/SignoutDark.png";
// import SignoutLight from '../../Assets/Logos/Auth/SignoutLight.png'
import { Link, useNavigate } from "react-router-dom";
import Products from "../Items/Products";
export default function Profile() {
  const [userData, setUserData] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    const savedData = localStorage.getItem("userInfo");
    try {
      const parsedData = savedData ? JSON.parse(savedData) : null;
      console.log("Parsed Data:", parsedData);
      setUserData(parsedData);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setUserData(null); // Set userData to null in case of parsing error
    }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("CartBtnClicked");
    navigate("/login");
    window.location.reload("/home");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={{ marginTop: "100px" }}>
        <h1
          style={{
            color: "black",
            fontFamily: "monospace",
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "bolder",
            letterSpacing: 1,
          }}
        >
          Profile
        </h1>

        {userData ? (
          <>
            <div
              style={{
                border: "2px solid grey",
                borderRadius: "10px",
                height: "60px",
                width: "250px",
                display: "block",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <h1
                style={{
                  color: "black",
                  fontFamily: "monospace",
                  textAlign: "center",
                }}
              >
                {" "}
                {userData.name}
              </h1>
            </div>

            <div
              style={{
                border: "2px solid grey",
                borderRadius: "10px",
                height: "60px",
                width: "250px",
                display: "block",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "10px",
              }}
            >
              <h1
                style={{
                  color: "black",
                  fontFamily: "monospace",
                  textAlign: "center",
                }}
              >
                {userData.number}
              </h1>
            </div>

            {/* <h1 style={{ color: "white",fontFamily:'monospace' }}>{userData.cart}</h1> */}
          </>
        ) : null}
      </div>
      <div
        style={{
          border: "5px solid lightblue",
          borderRadius: "10px",
          height: "60px",
          width: "250px",
          display: "block",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginLeft: "20px",
          }}
        >
          <img
            src={SignoutDark}
            style={{
              height: "25px",
              width: "25px",
              marginTop: "10px",
              marginLeft: "10px",
            }}
          />

          <Link style={{ textDecoration: "none" }} to="/login">
            <h1
              onClick={logoutHandler}
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginLeft: "10px",
                color: "black",
                textDecoration: "none",
                fontFamily: "monospace",
                marginTop: "-0.01px",
              }}
            >
              Signout
            </h1>
          </Link>
        </div>
      </div>

      {/* <div  onClick={logoutHandler} style={{marginTop:"25px", display:'flex',flexDirection:'row',alignContent:'center',marginLeft:'20px'}}>
        <p style={{textAlign:'center',fontWeight:'bold'}}>Signout </p>
     <img src= {SignoutDark} style={{height:'25px',width:'25px',marginTop:"15px",marginLeft:'10px' }}/>    
    </div>
    <div  onClick={logoutHandler} style={{marginTop:"25px", display:'flex',flexDirection:'row',alignContent:'center',marginLeft:'20px'}}>
        <p style={{textAlign:'center',fontWeight:'bold'}}>Signout </p>
     <img src= {SignoutDark} style={{height:'25px',width:'25px',marginTop:"15px",marginLeft:'10px' }}/>    
    </div> */}

      <Products />
      <a href="/TermsOfService" style={{ color: "blue", textAlign: "center" }}>
        Terms and Conditions
      </a>
      <a href="/privacypolicy" style={{ color: "blue", textAlign: "center" }}>
        Privacy Policy
      </a>
      <a href="/Refundpolicy" style={{ color: "blue", textAlign: "center" }}>
       Shipping , Cancellation & Refund{" "}
      </a>
     
      <div style={{ display: "flex", flexDirection: "row", marginRight:'auto',marginLeft:'auto' }}>
        <p  style={{ textAlign: "center", color: "black" }}>Contact us at :</p>
        <p style={{ textAlign: "center", color: "blue" }}>
          beingsatyasanatani@gmail.com
        </p>
      </div>
    </div>
  );
}
