import React, { useState, createContext, useContext } from "react";
import Header from "../../Components/NavigationBar/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../Constant";

const ForgotPassword = () => {
  const [number, setNumber] = useState("");

  const navigate = useNavigate();

  //   const submitHandler = async (e) => {
  //     e.preventDefault();
  //     if (!number || !password) {
  //       console.log("data is not filled");
  //       return;
  //     }
  //     try {
  //       const config = {
  //         headers: {
  //           "Content-type": "application/json",
  //         },
  //       };
  //       const response = await axios.post(
  //         `${BASE_URL}/api/userprofile/login`,
  //         {
  //           number,
  //           password,
  //         },
  //         config
  //       );
  //       Setourmessage(response.data.message);

  //       // console.log("LOGIN.................");
  //       //  console.log("Server response:", response);
  //       // const userName = response.data.data.name;
  //       // localStorage.setItem("userName", userName);

  //       //localStorage.setItem("userInfo",  response);

  //       console.log("Server Response:", response.data.message);

  //       localStorage.setItem("userInfo", JSON.stringify(response.data));
  //       navigate("/Home");

  //       console.log("done");
  //       setLoggedIn(true);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  // console.log("this is the messsage", ourmessage);

  const UsernumberInString = number?.toString();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/api/userprofile/forgotpassword`,
        {
          number: UsernumberInString,
          // password,
          // otp
        }
      );
      if (
        response.status === 200 &&
        response.data.message === "OTP verification code send"
      ) {
        console.log("OTP verified successfully");
         navigate("/ChangePassword");
      } else {
        console.log("Otp verification not send");
      }

      
      console.log("Otp send successfully");
      localStorage.setItem("UsernumberForForgot", number);

      //  navigate("/Home")
      //   window.location.href = "/Home";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{backgroundColor:'black',height:'1000px'}}>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          position: "relative",
          top: "200px",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            position: "fixed",
            borderRadius: "10px",
            height: "300px",
            width: "300px",
            boxShadow:
              "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
          }}
        >
          <h2 style={{ color: "white", textAlign: "center" }}>
            Forgot Password
          </h2>

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              style={{
                marginTop: "25px",
                borderWidth: 4,
                borderRadius: 50,
                borderColor: "white",
                width: 250,
                height: 25,
                shadowColor: "white",
                elevation: 1,
                backgroundColor: "#323232",
                color: "white",
                alignContent: "center",
                alignSelf: "center",
              }}
              placeholder="ᴘʜᴏɴᴇ"
              placeholderTextColor="white"
              type="number"
              onChange={(e) => setNumber(e.target.value)}
              value={number}
            />
            <br></br>
            {/* <p style={{ color: "white" }}>This is response{ourmessage}</p> */}

            <button
              // onPress={() => {
              //   SignUp();
              // }}
              onClick={submitHandler}
              style={{
                marginTop: "25px",
                backgroundColor: "black",
                borderRadius: "100px",
                width: 250,
                height: "50px",
                shadowColor: "white",
                border: "2px solid lightblue",
                boxShadow:
                  "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 25,
                  letterSpacing: 1,
                  textAlign: "center",
                  marginTop: "5px",
                  color: "white",
                }}
              >
                Continue
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
