// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productmargin {
    /* margin-left: 10px; */
    margin-left: 10px;
}

.rowdirection {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.productContainer {
    background-color:white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    /* background: linear-gradient(to bottom, #099BFF, #9999c3); */
    /* box-shadow:0 4px 8px 0 rgba(255, 255, 255, 0.8), 0 6px 20px 0 rgba(255, 255, 255, 0.19); */
    height: 480px;
    width: 300px;
    padding-top: 10px;
    border-radius: 10px;
    align-content: "center";
    margin-bottom: 20px;
}

@media (max-width:600px) {
    .productmargin {
        /* background-color: white; */
        margin-left: 0px;
        display: flex;
        flex-direction: center;
        justify-content: center;
        align-content: center;
    }

    .rowdirection {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/Css/cramoxstyles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,4EAA4E;IAC5E,kFAAkF;IAClF,8DAA8D;IAC9D,6FAA6F;IAC7F,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI;QACI,6BAA6B;QAC7B,gBAAgB;QAChB,aAAa;QACb,sBAAsB;QACtB,uBAAuB;QACvB,qBAAqB;IACzB;;IAEA;QACI,aAAa;QACb,sBAAsB;QACtB,uBAAuB;IAC3B;AACJ","sourcesContent":[".productmargin {\n    /* margin-left: 10px; */\n    margin-left: 10px;\n}\n\n.rowdirection {\n    display: flex;\n    flex: 1;\n    flex-direction: row;\n    justify-content: space-evenly;\n    flex-wrap: wrap;\n}\n\n.productContainer {\n    background-color:white;\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */\n    /* background: linear-gradient(to bottom, #099BFF, #9999c3); */\n    /* box-shadow:0 4px 8px 0 rgba(255, 255, 255, 0.8), 0 6px 20px 0 rgba(255, 255, 255, 0.19); */\n    height: 480px;\n    width: 300px;\n    padding-top: 10px;\n    border-radius: 10px;\n    align-content: \"center\";\n    margin-bottom: 20px;\n}\n\n@media (max-width:600px) {\n    .productmargin {\n        /* background-color: white; */\n        margin-left: 0px;\n        display: flex;\n        flex-direction: center;\n        justify-content: center;\n        align-content: center;\n    }\n\n    .rowdirection {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
