import React, {useState, createContext, useContext, useEffect} from 'react';

const UserDataContext = createContext();

export const Contextprovider = ({children}) => {
  const [age, setage] = useState('Shivam');


  const [userData, setUserData] = useState();

  useEffect(() => {
    const savedData = localStorage.getItem("userInfo");
    try {
      const parsedData = savedData ? JSON.parse(savedData) : null;
      console.log("Parsed number..................:", parsedData.number);
      setUserData(parsedData);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setUserData(null); // Set userData to null in case of parsing error
    }
  }, []);

  return (
    <UserDataContext.Provider value={{userData,setUserData}}>{children}</UserDataContext.Provider>
  );
};

export const useAge = () => {
  return useContext(UserDataContext);
};

export default Contextprovider;
