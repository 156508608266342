import React, { useState } from "react";
import Header from "../../Components/NavigationBar/Header";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Constant";
const Signup = () => {
  //   const [showText, setShowText] = React.useState(true);
  //   React.useEffect(() => {
  //     const interval = setInterval(() => {
  //       setShowText(!showText);
  //     }, 5000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   });
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [NumberExistsMessage,setNumberExistsMessage] = useState();

  // const [otp, setOtp] = useState("");
  // const [isOtpSent, setIsOtpSent] = useState(false);

  // const sendOtpHandler = async (e) => {
  //   e.preventDefault();

  //   // Add logic to send OTP to the user's phone number
  //   try {
  //     // Make an API request to send OTP
  //     const response = await axios.post(
  //       "http://localhost:5000/api/userprofile/sendotp",
  //       { number }
  //     );

  //     console.log("OTP sent successfully");
  //     setIsOtpSent(true);
  //   } catch (error) {
  //     console.log("Error sending OTP:", error);
  //   }
  // };

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!name || !number || !password) {
      console.log("data is not filled");
      return;
    }
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}/api/userprofile/signup`,
        {
          name,
          number,
          password,
        },
        config
      );
      localStorage.setItem("Usernumber", number);
      // console.log('Server response:', response);
      // localStorage.setItem("userInfo", response);

      if (
        response.status === 200 &&
        response.data.message === "Number already exists"
      ) {
        setNumberExistsMessage("Number already exists 😛");
      } else {
        console.log("done");
        navigate("/Otpverify");
      }



    } catch (error) {
      console.log(error);
    }
  };

  const otphandler = () => {
    console.log("otp handler is working");
    // navigate("/Otpverify")
  };

  return (
    <div style={{backgroundColor:'black',height:'1000px'}}>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          position: "relative",
          top: "150px",
          // marginRight: "20%",
          // marginLeft: "20%",
          borderRadius: "10px",
        }}
      >
         
        <div
          style={{
            borderRadius: "10px",
            height: "420px",
            width: "300px",
            boxShadow:
              "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ color: "white", textAlign: "center" }}>ꜱɪɢɴᴜᴘ</h1>
       
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <input
              style={{
                marginTop: "20px",
                borderWidth: 4,
                borderRadius: 50,
                borderColor: "white",
                width: "250px",
                height: 25,
                shadowColor: "white",
                elevation: 1,
                backgroundColor: "#323232",
                color: "white",
                //  alignContent:'center',
                alignSelf: "center",
              }}
              placeholder="ɴᴀᴍᴇ"
              placeholderTextColor="white"
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <br></br>

            <input
              style={{
                marginTop: "20px",
                borderWidth: 4,
                borderRadius: 50,
                borderColor: "white",
                alignSelf: "center",
                width: 250,
                height: 25,
                shadowColor: "white",
                elevation: 1,
                backgroundColor: "#323232",
                color: "white",
              }}
              placeholder="ᴘʜᴏɴᴇ"
              placeholderTextColor="white"
              type="number"
              onChange={(e) => setNumber(e.target.value)}
              value={number}
            />
            <br></br>

            <input
              style={{
                marginTop: "20px",
                borderWidth: 4,
                borderRadius: 50,
                borderColor: "white",
                alignSelf: "center",
                width: 250,
                height: 25,
                backgroundColor: "#323232",
                color: "white",
              }}
              placeholder="ᴘᴀꜱꜱᴡᴏʀᴅ"
              placeholderTextColor="white"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
  <p style={{ textAlign: "center", color: "lightgreen" }}>
            {NumberExistsMessage}
          </p>
            {/* {!isOtpSent ? (
              <button
                onClick={sendOtpHandler}
                style={{
                  marginTop: "25px",
                  marginLeft: "35px",
                  backgroundColor: "#FF9199",
                  borderRadius: "100px",
                  width: 300,
                  height: "50px",
                  boxShadow:
                    "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    letterSpacing: 1,
                    color: "white",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  ᴄʟɪᴄᴋ ᴛᴏ ꜱᴇɴᴅ ᴏᴛᴘ
                </p>
              </button>
            ) : (
              <>
                <input
                  style={{
                    marginTop: "25px",
                    marginLeft: "50px",
                    borderWidth: 4,
                    borderRadius: 50,
                    paddingLeft: 10,
                    borderColor: "white",
                    alignSelf: "center",
                    width: 250,
                    height: 25,
                    shadowColor: "white",
                    elevation: 1,
                    backgroundColor: "#323232",
                    color: "white",
                  }}
                  placeholder="ᴇɴᴛᴇʀ ᴏᴛᴘ"
                  placeholderTextColor="white"
                  type="text"
                  onChange={(e) => setOtp(e.target.value)}
                  value={otp}
                />
                </>)} */}

            <button
              // onPress={() => {
              //   SignUp();
              // }}
              onClick={submitHandler}
              style={{
                backgroundColor: "black",
                borderRadius: "100px",
                width: 250,
                height: "50px",
                shadowColor: "white",
                border: "2px solid lightblue",
                boxShadow:
                  "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 25,
                  letterSpacing: 1,
                  color: "white",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                ꜱɪɢɴᴜᴘ
              </p>
            </button>
          </div>
          <p style={{ textAlign: "center", color: "grey" }}>
            <Link style={{ color: "grey", textDecoration: "none" }} to="/login">
              ​​ ʟᴏɢɪɴ ᴛᴏ ᴄᴏɴᴛɪɴᴜᴇ →
            </Link>
          </p>

          {/* <p
              style={{
                marginLeft:'90px',
                color: "lightblue",
                fontSize: 20,
                display: showText ? "none" : "flex",
              }}
            >
              ᴡᴀɴᴛ ᴛᴏ ʙᴇ ᴏɴ ʙᴏᴀʀᴅ ?
            </p> */}
        </div>
      </div>
    </div>
  );
};
export default Signup;
