import React, { useState, createContext, useContext } from "react";
import Header from "../../Components/NavigationBar/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../Constant";

const ChangePassword = () => {
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [Passwordchangemessage, setPasswordchangemessage] = useState("");

  const navigate = useNavigate();

  const Usernumber = localStorage.getItem("UsernumberForForgot");
  const UsernumberinString = Usernumber?.toString();
  console.log(UsernumberinString);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BASE_URL}/api/userprofile/changepassword`,
        {
          number: UsernumberinString,
          password,
          otp,
        }
      );
      console.log("Password changed");
      if (
        response.status === 200 &&
        response.data.message === "Password changed successfully"
      ) {
        console.log("Password changed successfully");
        //await navigate("/login");
        setPasswordchangemessage("Password changed successfully 😊");

        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        console.log("not changed may be");
      }

      //  navigate("/Home")
      //   window.location.href = "/Home";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{backgroundColor:'black',height:'1000px'}}>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          position: "relative",
          top: "200px",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            position: "fixed",
            borderRadius: "10px",
            height: "400px",
            width: "300px",
            boxShadow:
              "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
          }}
        >
          <h3 style={{ color: "white", textAlign: "center" }}>New Password</h3>

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              style={{
                marginTop: "25px",
                borderWidth: 4,
                borderRadius: 50,
                borderColor: "white",
                width: 250,
                height: 25,
                shadowColor: "white",
                elevation: 1,
                backgroundColor: "#323232",
                color: "white",
                alignContent: "center",
                alignSelf: "center",
              }}
              placeholder="OTP"
              placeholderTextColor="white"
              type="text"
              onChange={(e) => setOtp(e.target.value)}
              value={otp}
            />

            <input
              style={{
                marginTop: "25px",
                borderWidth: 4,
                borderRadius: 50,
                borderColor: "white",
                width: 250,
                height: 25,
                shadowColor: "white",
                elevation: 1,
                backgroundColor: "#323232",
                color: "white",
                alignContent: "center",
                alignSelf: "center",
              }}
              placeholder="New Password"
              placeholderTextColor="white"
              type="text"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />

            <br></br>
            {/* <p style={{ color: "white" }}>This is response{ourmessage}</p> */}

            <button
              style={{
                marginTop: "25px",
                backgroundColor: "black",
                borderRadius: "100px",
                width: 250,
                height: "50px",
                shadowColor: "white",
                border: "2px solid lightblue",
                boxShadow:
                  "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
              }}
              onClick={submitHandler}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 25,
                  letterSpacing: 1,
                  textAlign: "center",
                  marginTop: "5px",
                  color: "white",
                }}
              >
                Continue
              </p>
            </button>
            <p style={{ color: "lightgreen" }}>{Passwordchangemessage}  </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
