import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAge } from "../../Provider/ContextProvider";
import karmyogiSweatshirt from "../../Assets/Product_images/KarmayogiSweatshirt.png";
import forwardimage from "../../Assets/Logos/forward.png";
//import Razorpay from "react-razorpay";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Constant";

const Order = () => {
  const [cartData, setCartData] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState();
  const [pincode, setPincode] = useState();
  const [address, setAddress] = useState();
  const [number, setNumber] = useState();
  const [productInfo, setProductInfo] = useState({});
  const [overallTotal, setOverallTotal] = useState(0);
  const [userprofile, setUserData] = useState();

  // const {valueParam } = useParams();

  const { userData } = useAge();
  const location = useLocation();

  // console.log(',,,,,,,,,,,,,,,,,,',typeof(Number(location.state.productPrice)))
  const productPrice = Number(
    location.state ? location.state.productPrice : null
  );

  const productnameinString = location.state
    ? location.state.productName?.toString()
    : null;
  const pricenameinString = location.state
    ? location.state.productPrice?.toString()
    : null;

  useEffect(() => {
    setQuantity(1);
    setTotal(productPrice || 0); // Set to 0 if productPrice is null
  }, [productPrice]);

  const handlePlus = async () => {
    setQuantity(quantity + 1);
    //  setTotal(total + 999);
    setTotal(total + productPrice);
    // await saveData();
  };

  const handleMinus = async () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      setTotal(total - productPrice);
      // await saveData();
    }
  };

  if (total > 100) {
    const overallTotalplusDelivery = total + 100;
    var payingamountinstring = overallTotalplusDelivery?.toString();
  }

  const quantityinstring = quantity?.toString();
  console.log("quantity", quantityinstring);

  const sizeinString = size?.toString();
  console.log(size);

  console.log("total price", payingamountinstring);

  const pincodeinString = pincode?.toString();
  console.log("here is the pincode", pincode);

  const addressinString = address?.toString();
  console.log("here is the address", address);

  // const [number, setNumber] = useState();
  // const [userData, setUserData] = useState();

  // useEffect(() => {
  //   const savedData = localStorage.getItem("userInfo");
  //   try {
  //     const parsedData = savedData ? JSON.parse(savedData) : null;
  //     console.log("Parsed Data..................:", parsedData.number);
  //     setNumber(parsedData.number);
  //     setUserData(parsedData);
  //   } catch (error) {
  //     console.error("Error parsing JSON:", error);
  //     setUserData(null); // Set userData to null in case of parsing error
  //   }
  // }, []);

  useEffect(() => {
    // window.location.reload();

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/userprofile/${userData.number}`
        );
        setCartData(response.data.cart);
        console.log(response.data.cart);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const savedData = localStorage.getItem("userInfo");
    try {
      const parsedData = savedData ? JSON.parse(savedData) : null;
      console.log("Parsed Data:", parsedData);
      setUserData(parsedData);
      setNumber("the number is.....",userprofile.number)
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setUserData(null); // Set userData to null in case of parsing error
    }
  }, []);

  const numberinString = number?.toString();
 
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const makePayment = async (e) => {
    e.preventDefault();
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("you are offline");
      return;
    }

    var options = {
      description: "Credits towards consultation",
      // image: 'https://i.imgur.com/3g7nmJC.png',
      currency: "INR",
      key: "rzp_live_FEBzZ0q1oVWWzK", // Your api key
      amount: `${payingamountinstring}` + "00",
      name: "Cramox",
      prefill: {
        email: "void@razorpay.com",
        contact: `${numberinString}`,
        name: "Razorpay Software",
      },
      theme: { color: "#000000" },
      handler: function (paymentresponse) {
        console.log("Payment successful:", paymentresponse);
        Orderbuynow(paymentresponse);
        // Additional actions after successful payment, if needed

        // You may want to call your backend to update the order status or perform other actions
        // Example: updateOrderStatus(response.razorpay_payment_id);
      },
    };
    // useRazorpay.open(options)
    //   .then(data => handlePaymentSuccess(data.razorpay_payment_id))
    //   .catch(error => handlePaymentError(error));
    const paymentobject = window.Razorpay(options);
    paymentobject.open();
  };

  const Orderbuynow = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}/api/shopping/apnadirectorder`,
        {
          productId: "someProductId",
          name: productnameinString,
          price: pricenameinString,
          quantity: quantityinstring,
          size: sizeinString,
          number: userData ? userData.number : null,
          pincode: pincodeinString,
          address: addressinString,
        },
        config
      );
      console.log("hey here is the cart ", response.data);
    } catch (error) {
      console.error(error);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Server responded with:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from the server");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error.message);
      }
    }
  };
  // const handlePaymentSuccess = async paymentId => {
  //   console.log(`Payment Successful: ${paymentId}`);
  //   // navigation.navigate('Paymentsuccess');
  // };

  // const handlePaymentError = async error => {
  //   console.log(`Error: ${error.code} |  ${error.description}`);
  //   await localStorage.removeItem('itemSecond');
  // };

  if (!size || !pincode || !address) {
    // If any of the required fields are missing, show an error message or handle it accordingly
    console.error("Please fill in all required fields");
  }

  // console.log("Here is the data", location.state.productImage);
  // console.log("my new data", location);

  // const { dataKey1, dataKey2 } = location.state || {};
  // console.log("DataKey1:", dataKey1); // Output: Brytvh
  // console.log("DataKey2:", dataKey2); // Output: AnotherValue

  

  return location.state ? (
    <div style={{ marginTop: "100px" }}>
      <h2 style={{ marginLeft: "25px" }}>
        {/* Checkout {location.state ? location.state : "hey"} */}
      </h2>

      {/* ITems */}
      {/* <p>Productid{valueParam}</p> */}
      <h1
        style={{
          color: "black",
          textAlign: "center",
          fontWeight: "bold",
          fontFamily: "monospace",
        }}
      >
        {/* KARMA YOGI SWEATHSIRT  */}
        {location.state ? location.state.productName : "Product Name"}
      </h1>
      {/* {userprofile ? (<p>number is {userprofile.number}</p>):(<p> no number </p>) } */}

      <img
        src={
          location.state ? `${BASE_URL}/${location.state.productImage}` : null
        }
        style={{
          height: "350px",
          width: "300px",
          marginTop: "10px",
          borderRadius: "5%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          border: "10px solid black",
        }}
      />

      <div
        // key={item._id}
        style={{
          margin: "25px",
          backgroundColor: "white",
          boxShadow:
            " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            borderRadius: "10px",
            borderWidth: "10px",
            borderColor: "red",
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
          }}
        >
          <img
            src={
              location.state
                ? `${BASE_URL}/${location.state.productImage}`
                : null
            }
            style={{
              height: "50px",
              width: "50px",
              marginTop: "10px",
              marginLeft: "10px",
              borderColor: "red",
              borderWidth: "20px",
              borderRadius: "10px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flex: "1",
              margin: "10px",
            }}
          >
            <p
              style={{
                color: "black",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              {/* KARMA YOGI SWEATHSIRT  */}
              {location.state ? location.state.productName : "Product Name"}
            </p>
            <p style={{ color: "blue" }}> ₹ {total}</p>
          </div>
        </div>

        {/* size & Increase Decrease button */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "space-between",
            margin: "5px",
          }}
        >
          <div style={{ marginTop: "15px" }}>
            <input
              type="radio"
              id="S"
              name="apnasize"
              value="S"
              onChange={(e) => setSize(e.target.value)}
              // onChange={() =>
              //   setProductInfo((prevInfo) => ({
              //     ...prevInfo,
              //     [item._id]: { ...prevInfo[item._id], size: "S" },
              //   }))
              // }
            />
            <label for="S">S</label>

            <input
              type="radio"
              id="M"
              name="apnasize"
              value="M"
              onChange={(e) => setSize(e.target.value)}

              // onChange={() =>
              //   setProductInfo((prevInfo) => ({
              //     ...prevInfo,
              //     [item._id]: { ...prevInfo[item._id], size: "M" },
              //   }))
              // }
            />
            <label for="M">M</label>

            <input
              type="radio"
              id="L"
              name="apnasize"
              value="L"
              onChange={(e) => setSize(e.target.value)}

              // onChange={() =>
              //   setProductInfo((prevInfo) => ({
              //     ...prevInfo,
              //     [item._id]: { ...prevInfo[item._id], size: "L" },
              //   }))
              // }
            />
            <label for="L">L</label>

            <input
              type="radio"
              id="XL"
              name="apnasize"
              value="XL"
              onChange={(e) => setSize(e.target.value)}

              // onChange={() =>
              //   setProductInfo((prevInfo) => ({
              //     ...prevInfo,
              //     [item._id]: { ...prevInfo[item._id], size: "Xl" },
              //   }))
              // }
            />
            <label for="Xl">XL</label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              margin: "10px",
            }}
          >
            <div
              style={{
                border: "2px solid #D3D3D3",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "2px",
                alignContent: "center",
                height: "40%",
              }}
            >
              <button
                style={{
                  backgroundColor: "white",
                  border: "none",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
                onClick={() => handleMinus()}
              >
                -
              </button>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  margin: "-10px 20px 30px 20px",
                }}
              >
                <p>{quantity}</p>
              </p>
              <button
                style={{
                  backgroundColor: "white",
                  border: "none",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
                onClick={() => handlePlus()}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Bill details */}
      <div
        style={{
          backgroundColor: "white",
          boxShadow:
            " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          margin: "25px",
          borderRadius: "10px",
        }}
      >
        <p
          style={{
            color: "balck",
            fontWeight: "bold",
            marginLeft: "10px",
          }}
        >
          Bill Details
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        >
          <p style={{ color: "black", fontWeight: "500" }}>Items Total</p>
          <p style={{ color: "black", fontWeight: "500" }}>₹ {total}</p>{" "}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        >
          <p style={{ color: "black", fontWeight: "500" }}>Delivery charges</p>
          <p style={{ color: "black", fontWeight: "500" }}>₹ 100</p>{" "}
        </div>
        <hr></hr>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        >
          <p style={{ color: "black", fontWeight: "500" }}>Total</p>
          {total > 100 ? (
            <p style={{ color: "blue", fontWeight: "500" }}>₹ {total + 100}</p>
          ) : (
            <p style={{ color: "blue", fontWeight: "500" }}>₹ 0</p>
          )}
        </div>
      </div>

      {/* Address */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "25px",
          //justifyContent: "space-between",
          backgroundColor: "black",
          boxShadow:
            "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
          height: "180px",
          padding: "10px",
        }}
      >
        <p style={{ fontWeight: "bold", color: "white" }}> Delivery Address </p>
        <input
          style={{ height: "25px" }}
          type="number"
          placeholder="Area Pincode"
          required={true}
          onChange={(e) => setPincode(e.target.value)}
        />

        <input
          style={{ marginTop: "26px", height: "25px" }}
          type="text"
          placeholder="Detailed Delivery Address"
          onChange={(e) => setAddress(e.target.value)}
          required="true"
        />
      </div>
      <hr></hr>

      {/* Place order */}
      <div
        style={{
          backgroundColor: "black",
          color: "white",
          fontWeight: "bold",
          height: "50px",
          textAlign: "right",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          margin: "10px",
          borderRadius: "10px",
          // boxShadow:
          //   " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",

          boxShadow:
            "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
        }}
      >
        <div>{total > 100 ? <p> ₹ {total + 100}</p> : <p>₹ 0</p>}</div>

        {/* if(!size || !pincode || !address) {
    // If any of the required fields are missing, show an error message or handle it accordingly
    console.error("Please fill in all required fields");
  } */}

        {!size || !pincode || !address ? (
          <p style={{ color: "red" }}> choose size & fill address details </p>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
            // onClick={buynow}
            onClick={makePayment}
          >
            <p>Place order </p>
            <img
              //  onClick={Orderbuynow}
              style={{ marginTop: "15px", height: "25px", width: "25px" }}
              src={forwardimage}
            />
          </div>
        )}
      </div>

      {/* <ul>
        {cartData.map((item) => (
          <div key={item._id}>
            Product: {item.name}, Price: {item.price}
          </div>
        ))}
      </ul> */}
    </div>
  ) : (
    <div>
      <h1 style={{ marginTop: "80px", color: "yellow" }}>Bhaag jao yeha se</h1>
    </div>
  );
};

export default Order;
