// import React, { useState } from "react";
// import Header from "../../Components/NavigationBar/Header";
// import axios from "axios";
// import { BASE_URL } from "../../Constant";
// const Productupload = () => {
//   const [productName, setproductName] = useState("");
//   const [productPrice, setproductPrice] = useState("");
//   const [productQuantity, setproductQuantity] = useState("");
//   const [productSize, setproductSize] = useState("");
//   const [productDetails, setproductDetails] = useState("");
//   const [productCategory, setproductCategory] = useState("");
//   const [productImage, setproductImage] = useState("");

//   const submitHandler = async (e) => {
//     e.preventDefault();
//     if (
//       !productName ||
//       !productPrice ||
//       !productQuantity ||
//       !productSize ||
//       !productDetails ||
//       !productCategory ||
//       !productImage
//     ) {
//       console.log("data is not filled");
//       return;
//     }

//     try {
//       // const config = {
//       //   headers: {
//       //     "Content-type": "application/json",
//       //   },
//       // };

//       const formData = new FormData();
//       formData.append("productName", productName);
//       formData.append("productPrice", productPrice);
//       formData.append("productQuantity", productQuantity);
//       formData.append("productSize", productSize);
//       formData.append("productDetails", productDetails);
//       formData.append("productCategory", productCategory);
//       formData.append("productImage", productImage);

//       const response = await axios.post(
//         `http://localhost:5000/api/Dashboard/productupload`,
//         formData,
//         // {
//         //   productName,
//         //   productPrice,
//         //   productQuantity,
//         //   productSize,
//         //   productDetails,
//         //   productCategory,
//         //   productImage,
//         // },
//         // config
//         {
//           headers: {
//             "Content-type": "multipart/form-data",
//           },
//         }
//       );
//       console.log("done");
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   // function handleImage(e) {
//   //   const file = e.target.files[0];
//   //   setproductImage(file);
//   // }

//   return (
//     <div>
//       {/* <Header /> */}
//       <div
//         style={{
//           display: "flex",
//           alignContent: "center",
//           justifyContent: "center",
//           position: "relative",
//           top: "400px",
//           marginRight: "20%",
//           marginLeft: "20%",
//           borderRadius: "10px",
//         }}
//       >
//         <div
//           style={{
//             // position: "fixed",
//             borderRadius: "10px",
//             width: "365px",
//             boxShadow:
//               "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
//           }}
//         >
//           <form>
//             <input
//               style={{
//                 marginTop: "20px",
//                 marginLeft: "50px",
//                 borderWidth: 4,
//                 borderRadius: 50,
//                 paddingLeft: 10,
//                 borderColor: "white",
//                 alignSelf: "center",
//                 width: 250,
//                 height: 25,
//                 shadowColor: "white",
//                 elevation: 1,
//                 backgroundColor: "#323232",
//                 color: "white",
//               }}
//               placeholder="product name"
//               placeholderTextColor="white"
//               type="text"
//               onChange={(e) => setproductName(e.target.value)}
//               value={productName}
//             />
//             <br></br>

//             <input
//               style={{
//                 marginTop: "25px",
//                 marginLeft: "50px",
//                 borderWidth: 4,
//                 borderRadius: 50,
//                 paddingLeft: 10,
//                 borderColor: "white",
//                 alignSelf: "center",
//                 width: 250,
//                 height: 25,
//                 shadowColor: "white",
//                 elevation: 1,
//                 backgroundColor: "#323232",
//                 color: "white",
//               }}
//               placeholder="product price"
//               placeholderTextColor="white"
//               type="text"
//               onChange={(e) => setproductPrice(e.target.value)}
//               value={productPrice}
//             />
//             <br></br>

//             <input
//               style={{
//                 marginTop: "25px",
//                 marginLeft: "50px",
//                 borderWidth: 4,
//                 borderRadius: 50,
//                 paddingLeft: 10,
//                 borderColor: "white",
//                 alignSelf: "center",
//                 width: 250,
//                 height: 25,
//                 backgroundColor: "#323232",
//                 color: "white",
//               }}
//               placeholder="product Quantity"
//               placeholderTextColor="white"
//               type="text"
//               onChange={(e) => setproductQuantity(e.target.value)}
//               value={productQuantity}
//             />
//             <br></br>

//             <input
//               style={{
//                 marginTop: "25px",
//                 marginLeft: "50px",
//                 borderWidth: 4,
//                 borderRadius: 50,
//                 paddingLeft: 10,
//                 borderColor: "white",
//                 alignSelf: "center",
//                 width: 250,
//                 height: 25,
//                 backgroundColor: "#323232",
//                 color: "white",
//               }}
//               placeholder="product Category"
//               placeholderTextColor="white"
//               type="text"
//               onChange={(e) => setproductCategory(e.target.value)}
//               value={productCategory}
//             />
//             <br></br>

//             <input
//               style={{
//                 marginTop: "25px",
//                 marginLeft: "50px",
//                 borderWidth: 4,
//                 borderRadius: 50,
//                 paddingLeft: 10,
//                 borderColor: "white",
//                 alignSelf: "center",
//                 width: 250,
//                 height: 25,
//                 backgroundColor: "#323232",
//                 color: "white",
//               }}
//               placeholder="product size"
//               placeholderTextColor="white"
//               type="text"
//               onChange={(e) => setproductSize(e.target.value)}
//               value={productSize}
//             />

//             <br></br>

//             <input
//               style={{
//                 marginTop: "25px",
//                 marginLeft: "50px",
//                 borderWidth: 4,
//                 borderRadius: 50,
//                 paddingLeft: 10,
//                 borderColor: "white",
//                 alignSelf: "center",
//                 width: 250,
//                 height: 25,
//                 backgroundColor: "#323232",
//                 color: "white",
//               }}
//               placeholder="product Details"
//               placeholderTextColor="white"
//               type="text"
//               onChange={(e) => setproductDetails(e.target.value)}
//               value={productDetails}
//             />

//             <br></br>
//             <input
//               style={{
//                 marginTop: "25px",
//                 marginLeft: "50px",
//                 borderWidth: 4,
//                 borderRadius: 50,
//                 paddingLeft: 10,
//                 borderColor: "white",
//                 alignSelf: "center",
//                 width: 250,
//                 height: 25,
//                 color: "white",
//               }}
//               placeholderTextColor="white"
//               type="file"
//               name="productImage"
//               // onChange={handleImage}
//               // onChange={(e) => setproductImage(e.target.files[0])}
//               // value={productImage}
//               // onChange={(e) => setproductImage(e.target.value)}
//               // value={productImage}
//               onChange={(e) => setproductImage(e.target.files[0])}
//               // value={productImage}
//             />

//             <button
//               // onPress={() => {
//               //   SignUp();
//               // }}
//               onClick={submitHandler}
//               style={{
//                 marginTop: "25px",
//                 marginLeft: "35px",
//                 backgroundColor: "#FF9199",
//                 borderRadius: "100px",
//                 width: 300,
//                 height: "50px",
//                 shadowColor: "white",
//                 boxShadow:
//                   "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
//               }}
//             >
//               <p
//                 style={{
//                   fontWeight: "bold",
//                   fontSize: 25,
//                   letterSpacing: 1,
//                   color: "white",
//                   textAlign: "center",
//                   marginTop: "10px",
//                 }}
//               >
//                 Upload
//               </p>
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Productupload;
