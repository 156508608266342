import React, { useEffect, useState } from "react";
import Cramoxlogo from "../../Assets/Logos/Cramoxsmall.jpeg";
import Cart from "../../Assets/Logos/Header/Cart.png";
import Account from "../../Assets/Logos/Header/Account.png";
import Search from "../../Assets/Logos/Header/search.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "../../Css/cramoxstyles.css";
import { useCart } from "../../Provider/CartProvider";
import BeingSanatani from "../../Assets/Logos/BeingSanatani.png";
const Header = () => {
  // const navigate = useNavigate();

  //const [showCartPopup, setShowCartPopup] = useState(false); // State to manage popup visibility

  // const toggleCartPopup = () => {
  //   setShowCartPopup(!showCartPopup);
  // };

  const { showCartPopup, setShowCartPopup } = useCart();

  useEffect(() => {
    // Get the value from localStorage
    const CartBtnClicked = localStorage.getItem("CartBtnClicked");
    console.log("Value from localStorage:", CartBtnClicked);
    if (CartBtnClicked === "true") {
      setShowCartPopup(true);
    } else {
      setShowCartPopup(false);
    }
  }, []); // Empty dependency array ensures this useEffect runs once when the component mounts

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.9)", // Black background with some transparency
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "75px",
        position: "fixed",
        width: "100%",
        top: 0,
        boxShadow:
          "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
        backdropFilter: "blur(0.5px)",
      }}
    >
      {/* <div style={{ marginLeft: "20px" }}>
      
      </div> */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "75px",
          flex: 1,
          justifyContent: "space-around",
        }}
      >
        <div>
          <Link to="/home">
            <img
              src={BeingSanatani}
              style={{ width: "150px" }}
              alt="Cramox Logo"
            />
          </Link>
        </div>

        <div>
          <img src={Search} style={{ height: "35px", margin: 10 }} />
        </div>

        <div>
          <Link style={{ textDecoration: "none" }} to="/Cart">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
              //onClick={toggleCartPopup}
            >
              <div style={{ height: "100px" }}>
                {showCartPopup && (
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      fontSize: "20px",
                      backgroundColor: "black",
                      borderRadius: "500%",
                      border: "2px solid lightblue",
                      height: "30px",
                      width: "28px",
                      textAlign: "center",
                      // boxShadow:
                      //   "0 4px 8px 0 rgba(255, 255, 255, 0.4), 0 6px 20px 0 rgba(255, 255, 255, 0.2)",
                    }}
                  >
                    +
                  </p>
                )}
              </div>
              <div style={{ marginTop: "30px" }}>
                <img src={Cart} style={{ height: "35px", margintop: 10 }} />
              </div>
            </div>
          </Link>
        </div>

        <div>
          <Link to="/Profile">
            <img src={Account} style={{ height: "35px", margin: 10 }} />
          </Link>
        </div>
      </div>
    </div>
  );
};

// const Cramoxlogoimages = styled.img`
//   width: 40%;
//   @media (max-width: 500px) {
//     width: 80%;
//   }
// `;

export default Header;
