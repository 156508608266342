import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAge } from "../../Provider/ContextProvider";
import karmyogiSweatshirt from "../../Assets/Product_images/KarmayogiSweatshirt.png";
import heart from "../../Assets/Logos/Heart.png";
import heartblack from "../../Assets/Logos/Heartblack.png";
import styled from "styled-components";
import "../../Css/cramoxstyles.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";
// import '../../../../backend/uploads'
import { BASE_URL } from "../../Constant";
import { useCart } from "../../Provider/CartProvider";
const Products = () => {

  const {addToCart,showCartPopup, setShowCartPopup} = useCart();

  const { userData } = useAge();
  const [productData, setProductData] = useState([]);
  const [productNames, setProductNames] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productId, setProductId] = useState("");
  const [productImage, setproductImage] = useState([]);

  const productNameInString = productNames?.toString();
  const productPriceInString = productPrice?.toString();
  const productIdInString = productId?.toString();
  const productImageInString = productImage?.toString();
 // const [showCartPopup, setShowCartPopup] = useState(false);     // State to manage popup visibility



  const addToCartHandler = async (
    productIdInString,
    productNameInString,
    productPriceInString,
    productImageInString,
    e
  ) => {
     e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
      ` ${BASE_URL}/api/user/cart`,
        {
          productId: productIdInString,
          name: productNameInString,
          price: productPriceInString,
          number: userData ? userData.number : null,
          Cartimage: productImageInString,
        },
        config
      );
      // if ('vibrate' in navigator) {
      //   // Vibrate for 1000ms (1 second)
      //   navigator.vibrate(1000);
      // } else {
      //   alert('Vibration API is not supported in this browser.');
      // }
      console.log("hey here is the cart ", response.data);


        setShowCartPopup(!showCartPopup);
        localStorage.setItem("CartBtnClicked",JSON.stringify(!showCartPopup), )
        //window.location.reload("/Cart")



    } catch (error) {
      console.error(error);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Server responded with:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from the server");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  // const buynow = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const config = {
  //       headers: {
  //         "Content-type": "application/json",
  //       },
  //     };
  //     const response = await axios.post(
  //       "http://localhost:5000/api/user/buynow",
  //       {
  //         productId: "someProductId",
  //         name: "roductnam",
  //         price: 20.99,
  //         number: userData ? userData.number : null,
  //         quantity: "10",
  //         size: "M",
  //         pincode: "274001",
  //         address: "Deoria",
  //       },
  //       config
  //     );
  //     console.log("hey here is the cart ", response.data);
  //   } catch (error) {
  //     console.error(error);

  //     if (error.response) {
  //       // The request was made and the server responded with a status code
  //       // that falls out of the range of 2xx
  //       console.error("Server responded with:", error.response.data);
  //       console.error("Status code:", error.response.status);
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       console.error("No response received from the server");
  //     } else {
  //       // Something happened in setting up the request that triggered an Error
  //       console.error("Error setting up the request:", error.message);
  //     }
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          //`http://localhost:5000/api/Dashboard/productupload`
          // ` https://cramoxclubbackend.onrender.com/api/Dashboard/productupload`
          `${BASE_URL}/api/Dashboard/productupload`
        );

        setProductData(response.data.ProductData);
        console.log("uploaded product response", response.data.ProductData);

        const data = response.data.ProductData;
        const names = data.map((product) => product.productName);
        const price = data.map((product) => product.productPrice);
        const id = data.map((product) => product._id);
        const image = data.map((product) => product.productImage);

        setProductNames(names);
        setProductPrice(price);
        setProductId(id);
        setproductImage(image);

        console.log(names);
        console.log(price);
        console.log(id);
        console.log("this is product image", image);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };
    fetchData();
  }, []);

  // const handleVibration = () => {
  //   if ('vibrate' in navigator) {
  //     // Vibrate for 1000ms (1 second)
  //     navigator.vibrate(1000);
  //   } else {
  //     alert('Vibration API is not supported in this browser.');
  //   }
  // };



  return (
    <div>
      <div className="rowdirection">
        {productData.toReversed().map((item) => (
          <div key={item._id}>
            <div className="productmargin">
              <div className="productContainer">
                <p
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontFamily: "monospace",
                    fontSize: "22px",
                  }}
                >
                  {item.productName}
                  {/* {addToCart} */}
                  {/* KARMA YOGI SWEATHSIRT */}
                </p>
                <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="/Order"
                    state={item}>
                {/* <div
                  style={{
                    // background: "linear-gradient(to bottom, #099BFF, #9999c3)", // Blue gradient
                    backgroundColor: "white",
                    boxShadow:
                      " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    height: "350px",
                    width: "250px",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    // borderBottomRightRadius: "200px",
                    // borderBottomLeftRadius: "50px",
                    // borderTopRightRadius: "50px",
                    // borderTopLeftRadius: "50px",
                    marginLeft: "25px",
                    // paddingRight: "5px",
                  }}
                > */}
                  {console.log(`Image URL: ${BASE_URL}/${item.productImage}`)}

                  <img
                    // src={`http://localhost:5000/${item.productImage}`}
                    src={`${BASE_URL}/${item.productImage}`}
                    alt={item.productImage}
                    // src={karmyogiSweatshirt}
                    style={{
                      height: "300px",
                      width: "250px",
                      marginTop: "10px",
                      margin: "auto",
                      marginLeft: "20px",
                      borderRadius: "10px",
                      // borderBottomRightRadius: "200px",
                      // borderBottomLeftRadius: "50px",
                      // borderTopRightRadius: "50px",
                      // borderTopLeftRadius: "50px",
                    }}
                  />
                {/* </div> */}
                </Link>
                
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    //marginTop: "10px",
                  }}
                >
                  <button
                    onClick={(e) =>
                      addToCartHandler(
                        item._id,
                        item.productName,
                        item.productPrice,
                        // item.Cartimage
                        item.productImage,
                        e
                      )
                    //  toggleCartPopup
                    }
                    style={{
                      marginTop:'5px',
                      backgroundColor: "black",
                      color: "white",
                      fontWeight:'bold',
                      borderWidth: "1px",
                      borderTopLeftRadius:'100px',
                      borderBottomLeftRadius:'100px',
                      borderTopRightRadius:'100px',
                      borderBottomRightRadius:'100px',
                      height: "40px",
                      width: "250px",
                      boxShadow:
                        " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      border: "2px solid lightblue",
                      fontFamily: "monospace",
                      touchAction:'manipulation',
                      marginRight:'auto',
                      marginLeft:'auto',
                    }}
                  >
                   Add to Cart
                  </button>

                  {/* <p style={{ color: "blue" }}>₹ {item.productPrice}</p> */}

                  <Link
                    style={{ color: "white", textDecoration: "none" , marginRight:'auto',
                    marginLeft:'auto',}}
                    to="/Order"
                    state={item}
                    // to={{
                    //   pathname: "/Order",
                    //   state: {
                    //     dataKey1: "Brytvh",
                    //     dataKey2: "AnotherValue",
                    //   },
                    // }}
                  >
                    <button
                      style={{
                        marginTop:'5px',
                        backgroundColor: "black",
                        color: "white",
                        fontWeight:'bold',
                        borderWidth: "1px",
                        borderTopLeftRadius:'100px',
                        borderBottomLeftRadius:'100px',
                        borderTopRightRadius:'100px',
                        borderBottomRightRadius:'100px',
                        height: "40px",
                        width: "250px",
                        boxShadow:
                          " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        border: "2px solid lightblue",
                        fontFamily: "monospace",
                       
                      }}
                      // onClick={buynow}
                    >
                      {/* productname="Karmayogisweatshirt" */}
                      Buy
                    </button>
                  </Link>
                </div>
                
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* 
      Here is the the product
      {userData ? (
        <p>from contextprovider {userData.number}</p>
      ) : (
        <p>no number</p>
      )} */}
    </div>
  );
};

// const styles = {
//   productContainer: {
//     backgroundColor: "white",
//     boxShadow:
//       " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", //black shadow
//     // boxShadow:
//     // "0 4px 8px 0 rgba(255, 255, 255, 0.5), 0 6px 20px 0 rgba(255, 255, 255, 0.9)", //white shadow
//     height: "450px",
//     width: "300px",
//     paddingTop: "35px",
//     borderRadius: "10px",
//     alignContent: "center",
//     marginTop:'10px'
//   },
// };

export default Products;
