import React, { useState, useEffect } from "react";
import "./App.css";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Auth/Login";
import Signup from "./Screens/Auth/Signup";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Profile from "./Screens/Profile/Profile";
import Header from "./Components/NavigationBar/Header";
import Contextprovider from "./Provider/ContextProvider";
import Cart from "./Screens/Cart/Cart";
import Orders from "./Screens/Orders/Order";
import Productupload from "./Screens/Dashboard/Productupload";
import Otpverify from "./Screens/Auth/Otpverify";
import ForgotPassword from "./Screens/Auth/ForgotPassword";
import ChangePassword from "./Screens/Auth/ChangePassword";
import NotFound from "./Screens/NotFound";
import CartProvider from "./Provider/CartProvider";
import Dashboard from "./Screens/Dashboard/Dashboard";
import CartDashboard from "./Screens/Dashboard/CartDashboard";
import PrivacyPolicy from "./Policy/PrivacyPolicy";
import TermsOfService from "./Policy/TermsOfService";
import RefundPolicy from "./Policy/RefundPolicy";
function App() {
  const [userData, setUserData] = useState();
  //const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    const savedData = localStorage.getItem("userInfo");
    try {
      const parsedData = savedData ? JSON.parse(savedData) : null;
      console.log("Parsed Data:", parsedData);
      setUserData(parsedData);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setUserData(null); // Set userData to null in case of parsing error
    }
  }, []);

  // useEffect(() => {
  //   // Check if user data exists and the app hasn't reloaded yet
  //   if (userData && !shouldReload) {
  //     setShouldReload(true); // Set shouldReload to true to trigger reload
  //   }
  // }, [userData]);

  // useEffect(() => {
  //   // If shouldReload is true, reload the app
  //   if (shouldReload) {
  //     window.location.reload();
  //     setShouldReload(false); // Reset shouldReload after reloading
  //   }
  // }, [shouldReload]);

  // if (isUserSignedIn) {
  //   window.location.reload(); // Reload the entire app
  // }

  // const needsReload = localStorage.getItem("needsReload") !== "true";

  // if(userData && needsReload){
  //   localStorage.setItem("needsReload", "false");
  //   window.location.reload(); // Reload the app
  // }

  return (
    <div>
      <Contextprovider>
        <CartProvider>
        <BrowserRouter>
        {userData ? (
          <>
            <Header />
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Home />} exact />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/Signup" element={<Signup />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/Order" element={<Orders />} />
              <Route path="/Otpverify" element={<Otpverify />} />
              <Route path="/Productupload" element={<Productupload />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route path="/ChangePassword" element={<ChangePassword />} />
              {/* <Route path="/Dashboard" element={<Dashboard/>}/>
              <Route path ="/CartDashboard" element={<CartDashboard/>} /> */}
              <Route path="/PrivacyPolicy"  element={<PrivacyPolicy/>}/>
              <Route path="/TermsofService" element={<TermsOfService/>}/>
              <Route path="/RefundPolicy" element={<RefundPolicy/>}/>
            </Routes>
            {/* <Footer/> */}
          </>
        ) : (
          
          <>
            <Header />
            <Routes>
            <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Home />} exact />
              <Route path="/home" element={<Home />} exact />
              <Route path="/Home" element={<Home />} exact />
              <Route path="/Signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/Signup" element={<Signup />} />
              <Route path="/cart" element={<Login />} />
              <Route path="/profile" element={<Login />} />
              <Route path="/Otpverify" element={<Otpverify />} />
              <Route path="/Productupload" element={<Productupload />} />
              <Route path="/Order" element={<Login />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route path="/ChangePassword" element={<ChangePassword />} />
              {/* <Route path="/Dashboard" element={<Dashboard/>}/>
              <Route path ="/CartDashboard" element={<CartDashboard/>} /> */}
              <Route path="/PrivacyPolicy"  element={<PrivacyPolicy/>}/>
              <Route path="/TermsofService" element={<TermsOfService/>}/>
              <Route path="/RefundPolicy" element={<RefundPolicy/>}/>
            </Routes>
          </>
        )}
        {/* <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/login" element={<Login />}  />
            <Route path="/Signup" element={<Signup />} />
          </Routes> */}
          </BrowserRouter>
          </CartProvider>
      </Contextprovider>
    </div>
  );
}
export default App;
